import axios from 'axios';
import Keycloak, { KeycloakInitOptions, KeycloakLogoutOptions } from 'keycloak-js';
import { Language } from '../models/Language';
import { SystemSettings } from '../models/SystemSettings';
import { User } from '../models/User';
import { UserRole } from '../models/UserRole';

class KeycloakAPI {
	private keycloak: any;

	initialize(url: string): Promise<boolean> {
		this.keycloak = new Keycloak({
			url,
			realm: 'ilmo',
			clientId: 'ilmo-frontend'
		});

		this.keycloak.onAuthRefreshSuccess = () => {
			localStorage.setItem('storedTokenExpireTime', this.getRefreshTokenExpireTime());
		};

		this.keycloak.onAuthRefreshError = () => {
			this.logout();
		};

		this.keycloak.onAuthLogout = () => {
			localStorage.removeItem('storedTokenExpireTime');
			this.logout();
		};

		const initOptions: KeycloakInitOptions = {
			checkLoginIframe: true
		};

		return this.keycloak.init(initOptions);
	}

	async getSystemSettings(): Promise<SystemSettings> {
		const response = await axios.get('/api/system-settings');

		return response.data;
	}

	login(idpHint?: string, language?: Language) {
		const locale = language?.toLowerCase();
		return this.keycloak.login({ idpHint, locale });
	}

	logout(logoutUrl?: string) {
		const logoutOptions: KeycloakLogoutOptions = {
			redirectUri: logoutUrl ? logoutUrl : 'https://112.fi/olet-kirjautunut-ulos-palvelusta'
		};
		return this.keycloak.logout(logoutOptions);
	}

	getToken() {
		return this.keycloak.token || '';
	}

	updateToken() {
		return this.keycloak.updateToken(30);
	}

	clear() {
		this.keycloak.clearToken();
	}

	getUserId() {
		return this.keycloak.subject;
	}

	getUser(): User | undefined {
		if (!this.keycloak) return undefined;

		return {
			id: this.keycloak.subject,
			name: this.keycloak.tokenParsed.name,
			givenName: this.keycloak.tokenParsed.given_name,
			email: this.keycloak.tokenParsed.email
		};
	}

	hasResourceRole(role: UserRole): boolean {
		return this.keycloak.hasResourceRole(role, 'ilmo-backend');
	}

	getRefreshTokenExpireTime() {
		return this.keycloak.refreshTokenParsed.exp;
	}
}

const keycloakAPI = new KeycloakAPI();
export default keycloakAPI;
